import HeroBlock from './HeroBlock'
import Quote from './Quote'
import Companies from './Companies'
import NewsSection from './NewsSection'
import MediaObject from './MediaObject'
import Text from './Text'
import SectionHeader from './SectionHeader'
import TeamMembers from './TeamMembers'
import CtaBlock from './CtaBlock'
import FullBodyImage from './FullBodyImage'
import TextPlusImage from './TextPlusImage'

export default {
  hero_block: HeroBlock,
  quote: Quote,
  quote_block: Quote,
  companies: Companies,
  news_section: NewsSection,
  media_object: MediaObject,
  text: Text,
  section_header: SectionHeader,
  team_members: TeamMembers,
  cta_block: CtaBlock,
  'full-width_image': FullBodyImage,
  text_plus_image: TextPlusImage,
}
