import React from "react"
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types"

const MediaObject = () => {
  return <div>Media Object</div>
}

MediaObject.propTypes = {}

export default MediaObject
