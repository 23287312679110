import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get'
import registry from '../../helpers/registry'
import SocialNetworkIcon from '../SocialNetworkIcon'
import './style.css'

const ContactUs = () => {
  const query = graphql`
    query ContactUsQuery {
      prismic {
        content: allGet_in_touchs {
          edges {
            node {
              button: touch_contact_button_text
              description: touch_description
              kicker: touch_kicker
              social_networks: touch_social_networks {
                social_network {
                  ... on PRISMIC_Social_network {
                    name
                    link {
                      ... on PRISMIC__ExternalLink {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic }) => {
        const { button, description, kicker, social_networks } = get(
          prismic,
          'content.edges[0].node'
        )

        return (
          <div className="contact-us">
            <div className="contact-us__i section">
              {kicker && <h4 className="contact-us__kicker">{kicker}</h4>}
              {description && (
                <div className="contact-us__description">
                  {RichText.render(description)}
                </div>
              )}
              <div className="contact-us__links">
                <div>
                  {button && (
                    <Link
                      to={registry.contact}
                      className="btn contact-us__btn_left"
                    >
                      {button}
                    </Link>
                  )}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={registry.newsletter}
                    className="btn contact-us__btn"
                  >
                    {registry.newsletterTitle}
                  </a>
                </div>
                {social_networks && (
                  <div className="contact-us__socials">
                    {social_networks.map(item => (
                      <a
                        href={item.social_network.link.url}
                        key={item.social_network.name}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-us__social link-simple"
                      >
                        <span className="contact-us__social__name underlined">
                          {item.social_network.name}
                        </span>
                        <SocialNetworkIcon
                          name={item.social_network.name}
                          classNames="contact-us__social__icon"
                        />
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }, query)}
    />
  )
}

ContactUs.propTypes = {}

ContactUs.defaultProps = {}

export default ContactUs
