import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import ScrollLock from 'react-scrolllock'
import Image from '../Image'
import ArrowIcon from '../../images/svg/gallery-arrow.svg'

const TeamMemberDetails = props => {
  const { member, onClose, onNext, onPrev } = props
  if (!member) return null

  const {
    description,
    name,
    job_title,
    popup_image,
    popup_image_sharp,
    image_sharp,
    image,
  } = member

  return (
    <div className="member-details">
      <ScrollLock>
        <div className="member-details__i">
          <div className="member-details__img">
            {popup_image && (
              <div className="--mob">
                <Image fluid={popup_image_sharp} image={popup_image} />
              </div>
            )}
            {image && (
              <div className="--desk">
                <Image fluid={image_sharp} image={image} />
              </div>
            )}
            <button
              type="button"
              className="member-details__ctrl slider-control --prev"
              onClick={onPrev}
            >
              <ArrowIcon className="slider-control__icon" />
            </button>
            <button
              type="button"
              className="member-details__ctrl slider-control --next"
              onClick={onNext}
            >
              <ArrowIcon className="slider-control__icon" />
            </button>
          </div>
          <button
            type="button"
            className="member-details__close"
            onClick={onClose}
          />
          <div className="member-details__info">
            <h2 className="member-details__title">{name}</h2>
            <p className="member-details__position">{job_title}</p>
            <div className="member-details__text">
              {RichText.render(description)}
            </div>
          </div>
        </div>
      </ScrollLock>
    </div>
  )
}

TeamMemberDetails.propTypes = {
  member: PropTypes.shape({
    description: PropTypes.object,
    name: PropTypes.string,
    job_title: PropTypes.string,
    popup_image: PropTypes.object,
    popup_image_sharp: PropTypes.object,
    image_sharp: PropTypes.object,
    image: PropTypes.object,
  }),
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
}

TeamMemberDetails.defaultProps = {
  member: {},
  onClose: null,
  onNext: null,
  onPrev: null,
}

export default TeamMemberDetails
