import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import cx from 'classnames'
import LinkIcon from '../../images/svg/link.svg'

const CtaLink = ({ to, children, className, type }) => {
  if (type === 'Link.document') {
    return (
      <Link to={to} className={cx('cta-link', className && className)}>
        <LinkIcon className="cta-link__icon" />
        {children}
      </Link>
    )
  }
  return (
    <a href={to} className={cx('cta-link', className && className)}>
      <LinkIcon className="cta-link__icon" />
      {children}
    </a>
  )
}

CtaLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
}

CtaLink.defaultProps = {
  className: '',
  type: 'Link.document',
}

export default CtaLink
