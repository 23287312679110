import React from 'react'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import Link from '../CtaLink'
import Image from '../Image'
import './CtaBlock.css'

const CtaBlock = props => {
  const node = get(props, 'data.primary') || {}
  const {
    has_background,
    kicker,
    cta_image_first,
    cta_image_first_sharp,
    cta_image_additional,
    cta_image_additional_sharp,
    text,
    title,
    url,
    url_title,
  } = node

  return (
    <div
      className={cx('cta', {
        '--complex': cta_image_additional,
        '--has-background': has_background === 'Yes',
      })}
    >
      <div className="cta__images">
        {cta_image_first && (
          <div className="cta__image">
            <Image fluid={cta_image_first_sharp} image={cta_image_first} />
          </div>
        )}
        {cta_image_additional && (
          <div className="cta__image-additional">
            <Image
              fluid={cta_image_additional_sharp}
              image={cta_image_additional}
            />
          </div>
        )}
      </div>
      <div className="cta__content section">
        <div className="cta__content__i">
          {kicker && <h3 className="cta__kicker">{kicker}</h3>}
          {title && <h2 className="cta__title">{title}</h2>}
          {text && <div className="cta__text">{RichText.render(text)}</div>}
        </div>
        {url && (
          <Link
            className="cta__link"
            to={`/${url._meta.uid}`}
            type={url._linkType}
          >
            {url_title}
          </Link>
        )}
      </div>
    </div>
  )
}

CtaBlock.propTypes = {}

export default CtaBlock
