import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import Image from '../Image'
import './style.css'

const HeroBlock = props => {
  const { node } = props
  const {
    kicker,
    title,
    content,
    has_background,
    image_sharp,
    hero_image,
  } = node

  return (
    <div
      className={cx('hero', {
        '--big': title,
        '--has-background': has_background === 'Yes',
      })}
    >
      <div className="section hero__i">
        {kicker && <h2 className="hero__kicker">{kicker}</h2>}
        {title && <h2 className="hero__title">{title}</h2>}
        {content && (
          <div className="hero__descr">{RichText.render(content)}</div>
        )}
      </div>
      {hero_image && (
        <div className="hero__image">
          <Image fluid={image_sharp} image={hero_image} />
        </div>
      )}
    </div>
  )
}

HeroBlock.propTypes = {
  node: PropTypes.shape({
    kicker: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.arrayOf({
      type: PropTypes.string,
      text: PropTypes.string,
      spans: PropTypes.array,
    }),
    has_background: PropTypes.string,
    hero_image: {
      dimensions: {
        height: PropTypes.number,
        width: PropTypes.number,
      },
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
    },
    image_sharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
}

HeroBlock.defaultProps = {
  node: {},
}

export default HeroBlock
