import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import get from 'lodash/get'
import Slider from 'react-slick'
import './style.css'
import ArrowIcon from '../../images/svg/gallery-arrow.svg'

const PrevArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="companies-slider__control slider-control --prev"
      onClick={onClick}
    >
      <ArrowIcon className="slider-control__icon" />
    </div>
  )
}

const NextArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="companies-slider__control slider-control --next"
      onClick={onClick}
    >
      <ArrowIcon className="slider-control__icon" />
    </div>
  )
}

const CompaniesSlider = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const query = graphql`
    query CompaniesList {
      prismic {
        companies: allCompanys {
          edges {
            node {
              name: company_name
              color: company_color
              logo: company_logo
            }
          }
        }
      }
      placeholder: file(relativePath: { eq: "company-placeholder.png" }) {
        childImageSharp {
          fixed(width: 1) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic, placeholder }) => {
        const companies = get(prismic, 'companies.edges')

        return (
          <div className="companies-slider">
            <div className="companies-slider__list">
              <Slider {...sliderSettings}>
                {companies.map(({ node }) => {
                  const { logo, color, name } = node
                  return (
                    <div className="companies-slider__item" key={name}>
                      <img
                        src={placeholder.childImageSharp.fixed.src}
                        alt=""
                        className="companies-slider__item__placeholder"
                      />
                      <div
                        className="companies-slider__item__i"
                        style={{ backgroundColor: color }}
                      >
                        <img
                          className="companies-slider__item__logo"
                          src={logo.url}
                          alt={name}
                        />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        )
      }, query)}
    />
  )
}

CompaniesSlider.propTypes = {
  node: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    logo: PropTypes.string,
  }),
}

CompaniesSlider.defaultProps = {
  node: {},
}

export default CompaniesSlider
