import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import TeamMember from '../TeamMembers'

const TeamMembers = props => {
  const members = get(props, 'data.fields')
  const node = get(props, 'data.primary') || {}
  if (!members) return null

  return <TeamMember node={node} members={members} />
}

TeamMembers.defaultProps = {
  data: {},
}

TeamMembers.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        links: {
          description: PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          }),
          image: PropTypes.string,
          job_title: PropTypes.string,
          name: PropTypes.string,
        },
      })
    ),
  }),
}

export default TeamMembers
