import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get'

const SectionHeader = ({ data }) => {
  const texts = get(data, 'primary')
  if (!texts) return null

  const {
    primary: { headline, kicker },
  } = data
  return (
    <div>
      {kicker && <h2>{kicker}</h2>}
      {headline && <h3>{RichText.render(headline)}</h3>}
    </div>
  )
}

SectionHeader.defaultProps = {
  data: {},
}

SectionHeader.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      headline: PropTypes.string,
      kicker: PropTypes.string,
    }),
  }),
}

export default SectionHeader
