import React from 'react'
import PropTypes from 'prop-types'
import FacebookIcon from '../../images/svg/facebook.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import LinkedinkIcon from '../../images/svg/linkedin.svg'
import MediumIcon from '../../images/svg/medium.svg'

const socialNetworkIcon = ({ name, classNames }) => {
  const mapping = {
    Facebook: FacebookIcon,
    Twitter: TwitterIcon,
    LinkedIn: LinkedinkIcon,
    Medium: MediumIcon,
  }

  const Icon = mapping[name]
  return Icon ? <Icon className={classNames} /> : null
}

socialNetworkIcon.propTypes = {
  name: PropTypes.string,
  classNames: PropTypes.string,
}

socialNetworkIcon.defaultProps = {
  name: null,
  classNames: '',
}

export default socialNetworkIcon
