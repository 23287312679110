import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import registry from '../../helpers/registry'
import Link from '../CtaLink'
import Image from '../Image'
import './style.css'

const MainPageAbout = props => {
  const { node } = props
  const {
    additionalImage,
    additionalImageSharp,
    singleImage,
    singleImageSharp,
    text,
    title,
    link_text,
  } = node

  const isEmpty =
    !additionalImage &&
    !additionalImageSharp &&
    !link_text &&
    !singleImage &&
    !singleImageSharp &&
    !_.get(text, '[0].text')

  if (isEmpty) return null

  return (
    <div className="main-about">
      <div className="main-about__images">
        {singleImage && (
          <div className="main-about__image">
            <Image fluid={singleImageSharp} image={singleImage} />
          </div>
        )}
        {additionalImage && (
          <div className="main-about__image-additional">
            <Image fluid={additionalImageSharp} image={additionalImage} />
          </div>
        )}
      </div>
      <div className="main-about__content section">
        <div className="main-about__content__i">
          {title && <h2 className="main-about__title">{title}</h2>}
          {text && <p className="main-about__text">{RichText.render(text)}</p>}
        </div>
        {link_text && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link className="main-about__link" to={registry.about}>
            {link_text}
          </Link>
        )}
      </div>
    </div>
  )
}

MainPageAbout.propTypes = {
  node: PropTypes.shape({
    additionalImage: {
      dimensions: {
        height: PropTypes.number,
        width: PropTypes.number,
      },
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
    },
    additionalImageSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    singleImage: {
      dimensions: {
        height: PropTypes.number,
        width: PropTypes.number,
      },
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
    },
    singleImageSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    text: PropTypes.string,
    title: PropTypes.string,
    link_text: PropTypes.string,
  }),
}

MainPageAbout.defaultProps = {
  node: {},
}

export default MainPageAbout
