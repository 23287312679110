import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get'
import SEO from '../seo'
import Layout from '../layout'
import slices from '../slices'
import ContactUs from '../ContactUs'
import MainPageBlocks from '../MainPageBlocks'

const renderSlice = (slice, index) => {
  const Component = slices[slice.type]
  if (!Component) return null
  return Component ? <Component data={slice} key={index} /> : null
}

const Page = props => {
  const { prismicObjectPath } = props
  // This component is used in types 'page' and 'homepage'. That's why we have a
  // possibility to fetch from different GraphQl instances
  const node =
    get(props, prismicObjectPath || 'data.prismic.allPages.edges[0].node') || {}
  const { has_contact_block, title, body, _meta } = node

  return (
    <Layout>
      {title && <SEO title={RichText.asText(title)} />}
      {/* We check if the page is homepage and then pass the unique main page blocks */}
      {_meta && _meta.uid === 'homepage' && <MainPageBlocks node={node} />}
      {body && body.map((slice, i) => renderSlice(slice, i))}
      {has_contact_block === 'Yes' && <ContactUs />}
    </Layout>
  )
}

Page.propTypes = {
  prismicObjectPath: PropTypes.string,
}

Page.defaultProps = {
  prismicObjectPath: 'data.prismic.allPages.edges[0].node',
}

export default Page
