import React, { Component } from 'react'
import newsFeed from '../../helpers/news-data'
import NewsPreview from '../NewsPreview'
import './style.css'

class MainPageNews extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.request = newsFeed().then(data => {
      this.request = null
      this.setState({ data })
    })
  }

  render() {
    const { data } = this.state

    return (
      <div className="main-page-news-section">
        <div className="main-page-news-section__i">
          <div className="main-page-news-section__list section">
            {!data.length && <div>Loading...</div>}
            {data.length &&
              data
                .slice(0, 3)
                .map(item => (
                  <NewsPreview
                    classNames="main-page-news-section__item"
                    item={item}
                    key={item.title}
                  />
                ))}
          </div>
        </div>
      </div>
    )
  }
}

export default MainPageNews
