import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import cx from 'classnames'
import _ from 'lodash'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Image from '../Image'
import LinkIcon from '../../images/svg/link.svg'
import TeamMemberDetails from './TeamMemberDetails'

const getMemberIndex = (name, members) => {
  const currentMember = _.find(members, member => member.items.name === name)
  let index = -1
  _.each(members, (data, idx) => {
    if (_.isEqual(data, currentMember)) {
      index = idx
    }
  })
  return index
}

const getNextIndex = (currentIndex, totalLength) =>
  currentIndex === totalLength - 1 ? 0 : currentIndex + 1

const getPrevIndex = (currentIndex, totalLength) =>
  currentIndex === 0 ? totalLength - 1 : currentIndex - 1

const showPrevPerson = (members, currentMember, setCurrentPersonShown) => {
  const userIndex = getMemberIndex(currentMember.items.name, members)
  setCurrentPersonShown(members[getPrevIndex(userIndex, members.length)])
}

const showNextPerson = (members, currentMember, setCurrentPersonShown) => {
  const userIndex = getMemberIndex(currentMember.items.name, members)
  setCurrentPersonShown(members[getNextIndex(userIndex, members.length)])
}

const TeamMember = props => {
  const { node, members } = props
  const { title, has_background } = node
  const [currentPersonShown, setCurrentPersonShown] = useState(null)

  return (
    <div className={cx('members', { '--has-background': has_background })}>
      <div className="members__container">
        {title && <h2 className="members__title section">{title}</h2>}
        <div className="member-list">
          {members.map(member => {
            const { name, job_title, image, image_sharp, _meta } = member.items
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className="member"
                key={_meta.uid}
                onClick={() => setCurrentPersonShown(member)}
              >
                <div className="member__i">
                  {image && (
                    <div className="member__image">
                      <Image fluid={image_sharp} image={image} />
                    </div>
                  )}
                  <div className="member__content">
                    <h2 className="member__name">{name}</h2>
                    <h3 className="member__position">{job_title}</h3>
                    <span className="member__link">
                      <LinkIcon className="member__link__icon" />
                      <span className="member__link__text">
                        More Information
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <CSSTransition
        classNames="animation-popup"
        in={!!currentPersonShown}
        timeout={100}
      >
        <div>
          {currentPersonShown && (
            <TransitionGroup component={null}>
              <CSSTransition
                classNames="change-person"
                timeout={300}
                key={currentPersonShown.items.name}
              >
                <TeamMemberDetails
                  member={currentPersonShown.items}
                  onClose={() => setCurrentPersonShown(null)}
                  onPrev={() =>
                    showPrevPerson(
                      members,
                      currentPersonShown,
                      setCurrentPersonShown
                    )
                  }
                  onNext={() =>
                    showNextPerson(
                      members,
                      currentPersonShown,
                      setCurrentPersonShown
                    )
                  }
                />
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

TeamMember.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string,
    has_background: PropTypes.string,
  }),
  members: PropTypes.array,
}

TeamMember.defaultProps = {
  node: null,
  members: [],
}

export default TeamMember
