import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import RichText from 'prismic-reactjs/src/Component'
import Image from '../Image'
import './Quote.css'

const Quote = props => {
  const node = get(props, 'data.primary') || {}
  const {
    content,
    author,
    link: {
      company_name,
      company_website: { url },
    },
    position,
    has_background,
    quote_image_sharp,
    quote_image,
  } = node

  return (
    <div
      className={cx('quote', {
        '--has-bg': has_background === 'Yes',
        '--left': position === 'Left',
      })}
    >
      {quote_image && (
        <div className="quote__image">
          <Image fluid={quote_image_sharp} image={quote_image} />
        </div>
      )}
      <div className="section quote__content">
        {content && (
          <div className="quote__text">{RichText.render(content)}</div>
        )}
        <div className="quote__author">
          {author && <h3 className="quote__name">{author}</h3>}
          {url ? (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="quote__company"
            >
              {company_name}
            </a>
          ) : (
            <span className="quote__company">{company_name}</span>
          )}
        </div>
      </div>
    </div>
  )
}

Quote.propTypes = {}

export default Quote
