import React, { useState } from 'react'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import ScrollLock from 'react-scrolllock'
import { CSSTransition } from 'react-transition-group'
import './Companies.css'
import LinkIcon from '../../images/svg/link.svg'

const cleanUrl = url => url.replace(/\/\/|.+\/\//, '').replace(/^\/|\/$/g, '')

const Companies = props => {
  const companies = get(props, 'data.fields')
  const data = get(props, 'data.primary') || {}
  const [currentCompanyShown, setCurrentCompanyShown] = useState(null)
  const { has_background } = data

  if (!companies) return null
  return (
    <div
      className={cx('companies', {
        '--has-background': has_background === 'Yes',
      })}
    >
      <div className="companies__list">
        {companies.map(({ company_link }) => {
          const { logo, color, name, description, website } = company_link
          return (
            <div className="company" key={name}>
              <div className="company__i" style={{ backgroundColor: color }}>
                <img className="company__logo" src={logo.url} alt={name} />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="company__overlay"
                  onClick={() => setCurrentCompanyShown(name)}
                />
                <div className="company__hover">
                  <h4 className="company__name">{name}</h4>
                  {RichText.render(description)}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <span className="company__link">
                    <a
                      href={website.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-link"
                    >
                      <LinkIcon className="cta-link__icon" />
                      {cleanUrl(website.url)}
                    </a>
                  </span>
                </div>
              </div>
              <CSSTransition
                classNames="animation-popup"
                in={name === currentCompanyShown}
                timeout={100}
              >
                <div>
                  {name === currentCompanyShown && (
                    <ScrollLock>
                      <div className="company__popup">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div
                          className="company__popup__back"
                          onClick={() => setCurrentCompanyShown(null)}
                        />
                        <div className="company__descr">
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                          <div
                            className="company__close"
                            onClick={() => setCurrentCompanyShown(null)}
                          />
                          <h4 className="company__name">{name}</h4>
                          {RichText.render(description)}
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <span className="company__link">
                            <LinkIcon className="cta-link__icon" />
                            <a
                              href={website.url}
                              className="company__link cta-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {cleanUrl(website.url)}
                            </a>
                          </span>
                        </div>
                      </div>
                    </ScrollLock>
                  )}
                </div>
              </CSSTransition>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Companies.propTypes = {}

export default Companies
