import { graphql } from 'gatsby'

/*

Do not write comments inside the fragments.
Because at the moment the gatsby-source-prismic-graphql plugin
cannot strip the comments out while building the preview query,
causing Prismic to return error 500 in preview.

*/

export const PageBodyQuote = graphql`
  fragment PageBodyQuote on PRISMIC_PageBodyQuote {
    type
    primary {
      content: quote_content
      author: quote_author
      link: quote_company_link {
        ... on PRISMIC_Company {
          company_name
          _meta {
            uid
          }
          company_website {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
      position: quote_position
      has_background: quote_has_background
      quote_image
      quote_image_sharp: quote_imageSharp {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export const PageBodyText = graphql`
  fragment PageBodyText on PRISMIC_PageBodyText {
    type
    primary {
      title: text_block_title
      content: text_block_content
      columns: text_column_layout
      has_background: text_block_has_background
    }
  }
`

export const PageBodyHero = graphql`
  fragment PageBodyHero on PRISMIC_PageBodyHero_block {
    type
    primary {
      kicker: hero_kicker
      title: hero_title
      content: hero_content
      has_background: hero_has_background
      hero_image
      image_sharp: hero_imageSharp {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export const PageBodySectionHeader = graphql`
  fragment PageBodySectionHeader on PRISMIC_PageBodySection_header {
    type
    primary {
      kicker: section_header_kicker
      headline: section_header
    }
  }
`

export const PageBodyCTABlock = graphql`
  fragment PageBodyCTABlock on PRISMIC_PageBodyCta_block {
    type
    primary {
      kicker: cta_kicker_headline
      title: cta_title
      text: cta_text
      url_title: cta_url_title
      has_background: cta_has_background
      url: cta_url {
        ... on PRISMIC__ExternalLink {
          url
          _linkType
        }
        ... on PRISMIC_Page {
          title
          _linkType
          _meta {
            uid
          }
        }
      }
      cta_image_first
      cta_image_first_sharp: cta_image_firstSharp {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cta_image_additional
      cta_image_additional_sharp: cta_image_additionalSharp {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export const PageBodyFullWidthImage = graphql`
  fragment PageBodyFullWidthImage on PRISMIC_PageBodyFullWidth_image {
    type
    primary {
      image
      image_full: imageSharp {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export const PageBodyTextPlusImage = graphql`
  fragment PageBodyTextPlusImage on PRISMIC_PageBodyText_plus_image {
    type
    primary {
      content: text_image_content
      position: text_image_position
      title: text_image_title
      text_image: text_image_image
      text_image_sharp: text_image_imageSharp {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export const PageBodyCompanies = graphql`
  fragment PageBodyCompanies on PRISMIC_PageBodyCompanies {
    type
    primary {
      has_background: companies_has_background
    }
    fields {
      company_link {
        _linkType
        ... on PRISMIC_Company {
          name: company_name
          description: company_description
          color: company_color
          logo: company_logo
          website: company_website {
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
    }
  }
`

export const PageBodyTeam = graphql`
  fragment PageBodyTeam on PRISMIC_PageBodyTeam_members {
    type
    primary {
      title: team_title
      has_background: team_has_background
    }
    fields {
      items: team_members_link {
        _linkType
        ... on PRISMIC_Team_member {
          name
          job_title
          description
          image
          image_sharp: imageSharp {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          popup_image
          popup_image_sharp: popup_imageSharp {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          _meta {
            uid
          }
        }
      }
    }
  }
`

export const PageBodyNews = graphql`
  fragment PageBodyNews on PRISMIC_PageBodyNews_section {
    type
    primary {
      news_section_title
    }
  }
`

export const PageBodyAllSlices = graphql`
  fragment PageBodyAllSlices on PRISMIC_PageBody {
    ... on PRISMIC_PageBodyQuote {
      ...PageBodyQuote
    }
    ... on PRISMIC_PageBodyText {
      ...PageBodyText
    }
    ... on PRISMIC_PageBodyHero_block {
      ...PageBodyHero
    }
    ... on PRISMIC_PageBodySection_header {
      ...PageBodySectionHeader
    }
    ... on PRISMIC_PageBodyCta_block {
      ...PageBodyCTABlock
    }
    ... on PRISMIC_PageBodyFullWidth_image {
      ...PageBodyFullWidthImage
    }
    ... on PRISMIC_PageBodyText_plus_image {
      ...PageBodyTextPlusImage
    }
    ... on PRISMIC_PageBodyCompanies {
      ...PageBodyCompanies
    }
    ... on PRISMIC_PageBodyTeam_members {
      ...PageBodyTeam
    }
    ... on PRISMIC_PageBodyNews_section {
      ...PageBodyNews
    }
  }
`
