import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import HeroBlock from '../Hero'
import Text from '../Text'
import CompaniesSlider from '../CompaniesSlider'
import MainPageAbout from '../MainPageAbout'
import MainPageNews from '../MainPageNews'

const MainPageBlocks = props => {
  const { node } = props
  const {
    about_imageSharp,
    about_image_additionalSharp,
    about_link_text,
    about_text,
    about_title,
    hero_description,
    hero_image,
    hero_imageSharp,
    hero_title,
    news_link_text,
    news_text,
    news_title,
    portfolio_intro,
    portfolio_link_text,
    portfolio_title,
  } = node

  return (
    <>
      <HeroBlock
        node={{
          title: hero_title,
          content: hero_description,
          has_background: 'Yes',
          image_sharp: hero_imageSharp,
          hero_image,
        }}
      />
      <Text
        node={{
          title: portfolio_title,
          content: portfolio_intro,
          link_text: portfolio_link_text,
          link: 'portfolio',
          has_background: 'Yes',
        }}
      />
      <CompaniesSlider
        node={{
          title: portfolio_title,
          intro: portfolio_intro,
          link_text: portfolio_link_text,
        }}
      />
      <MainPageAbout
        node={{
          singleImage: about_imageSharp,
          singleImageSharp: about_imageSharp,
          additionalImage: about_image_additionalSharp,
          additionalImageSharp: about_image_additionalSharp,
          text: about_text,
          title: about_title,
          link_text: about_link_text,
        }}
      />
      <Text
        node={{
          title: news_title,
          content: news_text,
          link_text: news_link_text,
          link: 'news',
          has_background: 'Yes',
        }}
      />
      <MainPageNews />
    </>
  )
}

MainPageBlocks.propTypes = {
  node: PropTypes.shape({
    about_imageSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    about_image_additionalSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    about_link_text: PropTypes.string,
    about_text: PropTypes.array,
    about_title: PropTypes.string,
    hero_description: PropTypes.string,
    hero_image: {
      dimensions: {
        height: PropTypes.number,
        width: PropTypes.number,
      },
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
    },
    hero_imageSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    hero_title: PropTypes.string,
    news_link_text: PropTypes.string,
    news_text: PropTypes.string,
    news_title: PropTypes.string,
    page_preview_imageSharp: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    portfolio_intro: PropTypes.string,
    portfolio_link_text: PropTypes.string,
    portfolio_title: PropTypes.string,
  }),
}

MainPageBlocks.defaultProps = {
  node: {},
}

export default MainPageBlocks
