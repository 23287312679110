import React from 'react'
import { graphql } from 'gatsby'
import values from 'lodash/values'
import Page from '../components/Page'
import * as Fragments from '../fragments/PageBodySlices'

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            has_contact_block
            children {
              page_link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                    lang
                    type
                  }
                  title
                }
              }
            }
            body {
              ...PageBodyAllSlices
            }
          }
        }
      }
    }
  }
`

const PageContainer = props => <Page {...props} />
PageContainer.fragments = values(Fragments)

export default PageContainer
