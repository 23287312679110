import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import cx from 'classnames'
import LinkIcon from '../../images/svg/link.svg'
import './style.css'

const dataFormatted = date =>
  `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`

const NewsPreview = ({ item, classNames }) => {
  const placeholderImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "newsletter-placeholder.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      key={item.title}
      className={cx('news-item', classNames, {
        '--dark': item.type === 'eventbrite',
      })}
    >
      <a
        className="news-item__i link-simple"
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.thumbnail && (
          <div className="news-item__image">
            <div
              className="news-item__image__i"
              style={{
                backgroundImage: `url(${item.thumbnail ||
                  placeholderImage.placeholderImage.childImageSharp.fluid
                    .src})`,
              }}
            />
          </div>
        )}
        <div className="news-item__content">
          {item.type === 'eventbrite' && (
            <h4 className="news-item__kicker">Event</h4>
          )}
          <h3
            className={cx('news-item__title', {
              '--big': item.type === 'eventbrite',
            })}
          >
            {item.title}
          </h3>
          {item.type === 'eventbrite' && (
            <p className="news-item__date">{dataFormatted(item.dateStamp)}</p>
          )}
          <span className="news-item__link">
            <LinkIcon className="news-item__icon" />
          </span>
        </div>
      </a>
    </div>
  )
}

NewsPreview.propTypes = {
  item: PropTypes.shape({
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    dateStamp: PropTypes.string,
  }).isRequired,
  classNames: PropTypes.string,
}

NewsPreview.defaultProps = {
  classNames: null,
}

export default NewsPreview
