import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import Link from '../CtaLink'
import './style.css'

const Text = props => {
  const { node } = props
  const { content, title, has_background, columns, link, link_text } = node

  if (node) {
    return (
      <div
        className={cx('section text', {
          '--has-background': has_background === 'Yes',
          '--has-columns': columns === 'Yes',
          '--has-link': link,
        })}
      >
        <div className="text__i">
          {title && <h2 className="text__title">{title}</h2>}
          {content && (
            <div className="text__content">{RichText.render(content)}</div>
          )}
        </div>
        {link && (
          <Link to={link} className="text__link">
            {link_text}
          </Link>
        )}
      </div>
    )
  }

  return null
}

Text.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.oneOf(PropTypes.string, PropTypes.array),
    has_background: PropTypes.string,
    title: PropTypes.string,
    columns: PropTypes.string,
    link: PropTypes.string,
    link_text: PropTypes.string,
  }),
}
Text.defaultProps = {
  node: {},
}

export default Text
