import React from 'react'
import get from 'lodash/get'
import cx from 'classnames'
import { RichText } from 'prismic-reactjs'
import './TextPlusImage.css'
import Image from '../Image'

const TextPlusImage = props => {
  const node = get(props, 'data.primary') || {}
  const { text_image, text_image_sharp, content, position, title } = node

  return (
    <div className={cx('text-image', { '--left': position === 'Left' })}>
      {text_image && (
        <div className="text-image__img">
          <Image fluid={text_image_sharp} image={text_image} />
        </div>
      )}
      <div className="section text-image__content">
        {title && <h2 className="text-image__title">{title}</h2>}
        {content && (
          <div className="image__text">{RichText.render(content)}</div>
        )}
      </div>
    </div>
  )
}

TextPlusImage.propTypes = {}

export default TextPlusImage
