import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Text from '../Text'

const TextSlice = props => {
  const node = get(props, 'data.primary') || {}
  return <Text node={node} />
}

TextSlice.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      content: PropTypes.arrayOf({
        type: PropTypes.string,
        text: PropTypes.string,
        spans: PropTypes.array,
      }),
      has_background: PropTypes.string,
      title: PropTypes.string,
      columns: PropTypes.string,
    }),
  }),
}
TextSlice.defaultProps = {
  data: {
    primary: {
      title: null,
      content: null,
      has_background: 'No',
      columns: 'No',
    },
  },
}

export default TextSlice
