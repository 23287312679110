import axios from 'axios'

// We use 3rd party service rss2json to parse medium posts as the only
// available option to parse medium page as the official API does not allow
// to parse data
// const MEDIUM_RSS_FEED_URL =
// 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40signalsvc&api_key=r2euyy6orex9dufoikydooilsf6hbfgn96ncuvtz'
const MEDIUM_RSS_FEED_URL =
  'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2Fsignals-vc&api_key=r2euyy6orex9dufoikydooilsf6hbfgn96ncuvtz'
// const EVENTBRITE_RSS_FEED_URL = `https://www.eventbriteapi.com/v3/organizations/227855186009/events/?token=RZWSD5FCJXFV5N7L5AAQ&status=live`

async function fetchData() {
  let mediumFeed
  let eventbriteFeed
  try {
    mediumFeed = await axios(MEDIUM_RSS_FEED_URL)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unfortunately, Medium feed could not be loaded')
  }

  // Uncomment below to enable eventbrite events fetaching
  // try {
  //   eventbriteFeed = await axios(EVENTBRITE_RSS_FEED_URL).then(response => {
  //     if (response.error) {
  //       throw new Error(response)
  //     }
  //     return response.data.events
  //   })
  // } catch (e) {
  //   // eslint-disable-next-line no-console
  //   console.error('Unfortunately, Eventbrite feed could not be loaded', e)
  // }

  const mediumPostsFormatted =
    mediumFeed && mediumFeed.data.items.length
      ? mediumFeed.data.items.map(post => ({
          title: post.title,
          date: post.pubDate,
          // safari requires the optional T or it doesn't recognise the string as a date
          dateStamp: new Date(post.pubDate.replace(' ', 'T')),
          thumbnail: post.thumbnail,
          link: post.link,
          content: post.description,
          type: 'medium',
        }))
      : []

  // const eventbritePostsFormatted = []
  const eventbritePostsFormatted = eventbriteFeed
    ? eventbriteFeed.map(event => ({
        title: event.name && event.name.text,
        date: event.start.utc,
        dateStamp: new Date(event.start.utc),
        thumbnail: event.logo && event.logo.url,
        link: event.url,
        content: event.description && event.description.html,
        type: 'eventbrite',
      }))
    : []

  const result = [...mediumPostsFormatted, ...eventbritePostsFormatted]
  return result.sort((a, b) =>
    a.dateStamp.getTime() < b.dateStamp.getTime() ? 1 : -1
  )
}

export default fetchData
