import React, { Component } from 'react'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import cx from 'classnames'
import newsFeed from '../../helpers/news-data'
import NewsPreview from '../NewsPreview'
import './NewsSection.css'

class NewsSection extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.request = newsFeed().then(data => {
      this.request = null
      const fullData = chunk(data, 6)
      this.setState({ fullData, data: fullData[0], currentChunk: 0 })
    })
  }

  getNextChunk = () => {
    const { data, fullData, currentChunk } = this.state
    const nextChunk = currentChunk + 1
    this.setState({
      data: [...data, ...fullData[nextChunk]],
      currentChunk: nextChunk,
    })
  }

  setItemClass = index => {
    const { currentChunk } = this.state
    if (currentChunk) {
      return index - currentChunk * 6
    }
    return index
  }

  render() {
    const node = get(this.props, 'data.primary') || {}
    const { news_section_title } = node
    const { data, fullData, currentChunk } = this.state

    return (
      <div className="news-section --has-background">
        <div className="news-section__i">
          {news_section_title && (
            <h2 className="news-section__title section">
              {news_section_title}
            </h2>
          )}
          <div className="news-section__list section">
            {!!data.length &&
              data.map((item, index) => (
                <NewsPreview
                  classNames={cx(
                    'news-section__item',
                    `--n${this.setItemClass(index)}`
                  )}
                  item={item}
                  key={item.link}
                />
              ))}
          </div>
          {!!data.length && currentChunk < fullData.length - 1 && (
            <span className="news-list__more">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span className="underlined" onClick={this.getNextChunk}>
                Show more
              </span>
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default NewsSection
