import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Hero from '../Hero'

const HeroSlice = props => {
  const node = get(props, 'data.primary') || {}
  return <Hero node={node} />
}

HeroSlice.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      kicker: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.arrayOf({
        type: PropTypes.string,
        text: PropTypes.string,
        spans: PropTypes.array,
      }),
      has_background: PropTypes.string,
      hero_image: {
        dimensions: {
          height: PropTypes.number,
          width: PropTypes.number,
        },
        alt: PropTypes.string,
        copyright: PropTypes.string,
        url: PropTypes.string,
      },
      hero_imageSharp: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
    }),
  }),
}

HeroSlice.defaultProps = {
  data: {
    primary: {
      kicker: null,
      title: null,
      content: null,
      has_background: 'No',
      hero_imageSharp: null,
      hero_image: null,
    },
  },
}

export default HeroSlice
